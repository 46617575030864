<template>
	<el-container class="container-main-box">
		<el-main class="main-box">
			<div class="invited-log" @click="invitedLogBtn"><img src="@/assets/img/account/invited_log.png" class="icon-log" alt=""> 邀请记录</div>
			<el-form  ref="editForm"
				size="small"
				:model="formLabel"
				label-position="left"
				label-width="140px"
				:rules="rules"
				>
				<div class="form-box">
					<el-form-item label="租户类型">
						<div class="status-box">
							<div v-for="(its,index) in userTypeArr" :class="['item',index==nIndex?'curr':'']" @click="navChange(index)">
								{{its.name}}
							</div>
						</div>
					</el-form-item>
					<el-form-item label="邀请方式" prop="invite_method">
						<el-radio-group v-model="formLabel.invite_method" @change="getInvitedMethods">
							<el-radio :label="1">定向邀请</el-radio>
							<el-radio :label="2">公开邀请</el-radio>
						</el-radio-group>
					</el-form-item>
					<template v-if="formLabel.invite_method==1">
						<el-form-item label="组织名称" prop="invite_name">
							<el-input class="form-input" v-model="formLabel.invite_name" @blur="validName"></el-input>
							<div class="name-info"  v-if="name_err">
								<span class="remark">{{formLabel.invite_name}}租户编号({{serial_number}})</span>已入驻平台，不能重复激活
							</div>
						</el-form-item>
						<el-form-item label="统一社会信用代码" prop="invite_code">
							<el-input class="form-input" v-model="formLabel.invite_code"  @blur="validCode"></el-input>
							<div class="name-info"  v-if="code_err">
								<span class="remark">{{formLabel.invite_code}}</span>已入驻平台，不能重复激活
							</div>
						</el-form-item>
						<el-form-item label="管理员" prop="contact">
							<vxe-table 
								class="mytable-scrollbar forms-table"
								highlight-hover-row
								border
								size="small"
								align="center"
								:data="contactData" >
								<vxe-column type="seq" title="序号" width="65"></vxe-column>
								<vxe-column title="联系人姓名">
									<template #default="{row}">
										<div v-if="row.edit">
											<el-input v-model="row.invite_contact_name">	</el-input>
										</div>
										<div v-else>
											{{row.invite_contact_name||'--'}}
										</div>
									</template>
								</vxe-column>
								<vxe-column>
									<template #header>
										<span class="red-remark">*</span> 手机号
									</template>
									<template #default="{row}">
										<div v-if="row.edit">
											<el-input v-model="row.invite_contact_mobile" @blur="changeMobile">	</el-input>
										</div>
										<div v-else>
											{{row.invite_contact_mobile||'--'}}
										</div>
									</template>
								</vxe-column>
								<vxe-column title="邮箱">
									<template #default="{row}">
										<div v-if="row.edit">
											<el-input v-model="row.invite_contact_email">	</el-input>
										</div>
										<div v-else>
											{{row.invite_contact_email||'--'}}
										</div>
									</template>
								</vxe-column>
								<vxe-column title="操作">
									<template #default="{row}">
										<div class="action-box">
											<span v-if="row.edit" class="empha" @click="contactYesBtn(row)">
												确定
											</span>
											<span v-else  class="empha" @click="editContact(row)">
												编辑
											</span>
											<span class="empha" @click="cancelBtn(row)" style="margin-left:8px;">删除</span>
										</div>
									</template>
								</vxe-column>
							</vxe-table>
						</el-form-item>
					</template>
					<template v-if="formLabel.invite_method==2">
						<div class="card-box">
							<invitedCard :key="invited_key" :text="share_content" :code_url="code_url"></invitedCard>
						</div>
					</template>
				</div>
			</el-form>
		</el-main>
		<el-footer>
			<div class="footer-box" v-if="formLabel.invite_method==1">
				<el-button size="small" class="footer-btn" @click="$router.go(-1)">取消</el-button>
				<el-button size="small" class="footer-btn" type="danger" @click="storeBtn">确定</el-button>
			</div>
		</el-footer>
	</el-container>
	</template>
	
	<script>
		import Clipboard from 'clipboard'
		import invitedCard from '@/views/mySelf/invitedCompany/components/invitedCard.vue'
		export default {
			components: {
				invitedCard,
			},
			data(){
				let vm = this;
				let rules = {
					invite_method:[{required:true,message:'请选择邀请方式'}],
					invite_name:[{required:true,message:'请输入企业名称',trigger:'blur'}],
					invite_code:[{required:true,trigger:'blur',
						validator:(rules,value,call)=>{
							if(value){
								// if(!/^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g.test(value)){
								if (!/^[0-9A-Z]{18}$/g.test(value)) {
									call(new Error("请输入正确的统一社会信用代码"))
								}else{
									call();
								}
							}else{
								call(new Error('统一社会信用代码不能为空'))
							}
						}
					
					}],
					contact:[{
						required:true,trigger:'blur',
						validator:(rules,value,call)=>{
							if(!vm.formLabel.invite_contact_mobile){
								call(new Error('管理员手机号不能为空'))
							}else{
								call()
							}
						}
					}]
				}
				let shareRules = {
					title:[{required:true,message:'请填写分享标题'}],
					img_path:[{required:true,message:'请上传分享图片'}],
					info:[{required:true,message:'请填写分享语'}],
				}
				return {
					headers:{
						title:"新增邀请",
						back:true,
					},
					shareRules,
					rules,
					code_img:'',
					imgKey:+ new Date(),
					shareForm:{
						title:'',
						img_path:'',
						info:'',
					},
					visible:false,
					invited_key:0,
					formLabel:{
						invite_method:1,
						invite_name:'',
						invite_code:'',
						invite_contact_name:'',
						invite_contact_mobile:'',
						invite_contact_email:'',
					},
					contactData:[{
						edit:true,
						invite_contact_name:'',
						invite_contact_mobile:'',
						invite_contact_email:'',
					}],
					share_content:'',
					code_url:'',
					company_name:'',
					userInfo:{},
					name_err:false,
					code_err:false,
					invite_info:{},
					nIndex:0,
					userTypeArr:[
						{
							name:'独立租户',
							value:1,
						},{
							name:'分平台',
							value:2,
						}
						,{
							name:'租户',
							value:0,
						}
					]
				}
			},
			created(){
				this.getInvitedSet();
			},
			mounted(){
				this.$store.commit("updatePageNav", [
				  { name: "邀请租户"},
				]);
			},
			methods: {
				typeBtn(data){
					this.formLabel.invite_type = data;
				},
				cancelBtn(row){
					row.invite_contact_name = '';
					row.invite_contact_mobile = '';
					row.invite_contact_email = '';
				},
				navChange(index){
					this.nIndex = index;
				},
				invitedLogBtn(){
					this.$router.push({
						path:'/mySelf/invitedCompany/invitedLog'
					})
				},
				validName(){
					if(this.formLabel.invite_name){
						let data = {
							name: this.formLabel.invite_name
						}
						this.$axios.postJSON('/base/company/isexist',data).then(res=>{
							if(res.code==200){
								let data = res.data;
								if(data){
									this.name_err = true;
									this.serial_number = data.serial_number
								}else{
									this.name_err = false;
								}
							}
						}).catch(err=>{
							console.log(err);
							err.msg && this.$message.error(err.msg||'系统错误,联系管理员');	
						})
					}
				},
				validCode(){
					if(this.formLabel.invite_code){
						let data = {
							company_code: this.formLabel.invite_code,
							hideLoad:true,
						}
						this.$axios.postJSON('/base/company/isexist',data).then(res=>{
							if(res.code==200){
								let data = res.data;
								if(data){
									this.code_err = true;
									this.serial_number = data.serial_number
								}else{
									this.code_err = false;
								}
							}
						}).catch(err=>{
							console.log(err);
							err.msg && this.$message.error(err.msg||'系统错误,联系管理员');	
						})
					}
				},
				getInvitedMethods(type){
					if(type==2 && !this.code_img){
						this.getShareCode();
					}
				},
				copyContentBtn(){
					var clipboard = new Clipboard('.tag-read');
					let _this = this;
					clipboard.on('success', e => {
						_this.$Message.success('复制成功');
					})
					clipboard.on('error', e =>{
						_this.$Message.error('该浏览器不支持复制');
					})
					this.clipboard = clipboard;
					setTimeout(()=>{
						this.clipboard.destroy &&this.clipboard.destroy()
					},500)
				},
				shareCancelBtn(){
					this.visible = false;
					this.shareForm.title = '';
					this.shareForm.img_path = '';
					this.shareForm.info = '';
					this.imgKey = + new Date();
				},
				shareImgBtn(data){
					this.shareForm.img_path = data.image_url;
				},
				changeMobile(event){
					this.formLabel.invite_contact_mobile = event.target.value;
				},
				contactYesBtn(row){
					if(!row.invite_contact_mobile){
						return this.$message.error("管理员必填字段不能为空")
					}else if(!this.$utils.regMobile(row.invite_contact_mobile)){
						return this.$message.error("请输入正确的手机号码")
					} else{
						row.edit = false;
						let form = this.formLabel;
						form.invite_contact_name = row.invite_contact_name;
						form.invite_contact_mobile = Number(row.invite_contact_mobile);
						form.invite_contact_email = row.invite_contact_email;
					}
				},
				editContact(row){
					row.edit = true;
				},
				storeBtn(){
					if(this.contactData[0].edit){
						return this.$message.error("请确定管理员信息")
					}
					this.$refs.editForm.validate(valid=>{
						if(valid){
							let data = this.$utils.cloneDeep(this.formLabel);
							data.invite_contact_mobile = Number(data.invite_contact_mobile);
							data.invite_set = this.invite_info;
							data.invite_type =this.userTypeArr[this.nIndex].value;
							data.invite_set =  {
								...this.invite_info
							}
							data.invite_place = 1;
							//邀请租户 
							console.log('sumbit',data)
							this.$axios.postJSON('/base/invite/common',data).then(res=>{
								if(res.code==200){
									this.$message.success("邀请成功");
									this.$router.push({
										path:'/mySelf/invitedCompany/invitedLog'
									})
								}
							})	
							
						}
					})
				},
				getInvitedSet(){ //获取邀请设置
					let platInfo = JSON.parse(localStorage.platInfo);
					let loginInfo = this.$store.state.loginInfo;
					let company = loginInfo.company;
					// console.log('getInvitedSet loginInfo',loginInfo);
					this.$axios.get('/base/invite/common/set?invite_type=0').then(res=>{
						let data = res.data;
						if(data && data.title){
							this.invite_info = data;
						}else{
							this.invite_info = {
								title:`${loginInfo.user_name}邀请您入驻${platInfo.name}`,
								img_path: platInfo.logo_url,
								info: platInfo.description,
							}
						}
					})
				},
				getShareCode(){
					if(this.code_url){
						return false;
					}
					let loginInfo = this.$store.state.loginInfo
					let company = loginInfo.company;
					let platInfo =  JSON.parse(localStorage.platInfo)
					let params = {
						invite_set:{
							...this.invite_info
						},
						invite_type:this.userTypeArr[this.nIndex].value,
						invite_method:2,
						invite_place:1,
					}
					let share_title = this.invite_info.title;
					this.$axios.postJSON('/base/invite/common',params).then(res=>{
						if(res.code == 200){
							let data = res.data;
							this.code_url = data.invite_link;
							this.share_content =  `${share_title}！${data.invite_link}`;
							this.invited_key = +new Date();
						}
					})
				
				},
			}
		}
	</script>
	
	<style scoped lang="scss">
		.container-main-box{
			width:1200px;
			margin:0 auto;
			background-color: #FFFFFF;
			padding: 20px;
		}
		.status-box{
			display: flex;
			.item{
				width:100px;
				border:1px solid #EFEFEF;
				text-align: center;
				cursor: pointer;
				&:first-child{
					border-radius: 4px 0 0 4px; 
				}
				&:last-child{
					border-radius: 0 4px 4px 0; 
				}
				&.curr{
					border-color:#F26161;
					color: #F56161;
				}
			}
		}
		.action-box{
			.empha{
				color:#F26161;
				cursor: pointer;
			}
		}
		$primary:#F26161;
		.radio-group {
			display: flex;
			.radio {
				width: 100px;
				height: 32px;
				// @include flexCenter();
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid #cdcdcd;
				cursor: pointer;
				&:first-child{
					border-radius: 4px 0 0 4px;
				}
				&:last-child{
					border-radius:0 4px 4px 0;
				}
				&.on {
					border: 1px solid $primary;
					color: $primary;
				}
			}
		}
		.main-box{
			flex:unset;
			position: relative;
			.invited-log{
				position: absolute;
				right:20px;
				top:20px;
				display: flex;
				align-items: center;
				color:#F26161;
				cursor: pointer;
				z-index: 10;
				.icon-log{
					width:16px;
					height: 16px;
					margin-right: 4px;
				}
			}
		}
		.form-box{
			padding-left:30px;
			margin-top:20px;
			.form-input{
				width:350px;
			}
			.name-info{
				display: inline;
				margin-left:10px;
				.remark{
					color:$primary;
				}
			}
		}
		.forms-table{
			.red-remark{
				color:#F26161;
			}
		}
		.footer-box{
			display:flex;
			justify-content: center;
			.footer-btn{
				width:80px;
				&:last-child{
					margin-left:20px;
				}
			}
		}
		.share-form{
			.form-input{
				width:350px;
			}
		}
		.card-box{
			display: flex;
			padding-left:140px;
			.content{
				width:240px;
				height:246px;
				border:1px solid #efefef;
				padding:10px 12px;
				.title{
					font-weight: 650;
				}
				.info{
					font-size: 13px;
					color:#999999;
				}
				.content1{
					margin-top: 5px;
					word-break:break-all;
					background-color: #FAFAFA;
					padding:10px; 
					line-height: 26px;
					height: 140px;
					// @include  ellipsisText(5);
					&.img-code{
						// @include flexCenter();
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				.btn-box{
					margin-top: 8px;
					text-align: right;
					.copy-btn{
						border-color:transparent;
						background-color: rgba($primary,.1);
						color:$primary;
					}
				}
			}
		}
	</style>
	
</div>


